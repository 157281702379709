import Post from "../post/Post"
import "./posts.scss"

export default function Posts() {
    return (
        <div className="posts">
            <Post/>
            <Post/>
            <Post/>
            <Post/>
        </div>
    )
}